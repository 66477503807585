import React from 'react';
import logo from '../assets/logo.png'; // Import your logo image

const Header = () => {
    return (
        <header className="bg-[#1a1b1f] flex justify-between items-center p-4 h-16 shadow-lg">
            {/* Left Section: Logo and Title */}
            <div className="flex items-center">
                <img src={logo} alt="Afking.lol Logo" className="h-10 w-10 mr-4" />
                <h1 className="text-3xl font-bold">Afking.lol</h1>
            </div>

            {/* Right Section: Store, Dashboard, Contact */}
            <div className="space-x-4">
                <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg">
                    Store
                </button>
                <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                    Dashboard
                </button>
                <button className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg">
                    Contact
                </button>
            </div>
        </header>
    );
};

export default Header;
