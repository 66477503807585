import React from 'react';
import '../index.css'; // Import global CSS
import logo from '../assets/logo.png'; // Import the logo image
import afkImage from '../assets/afk-image.png'; // Replace with your actual image path

const plans = [
	{
		title: "Budget",
		price: "$1.99",
		description: "Basic features for beginners.",
		features: [
			{ name: "Chat Logging", included: "yes" },
			{ name: "Up to 5 Accounts", included: "yes" },
			{ name: "Send Messages & Commands", included: "yes" },
			{ name: "24/7 In the Cloud AFKing", included: "yes" },
		],
	},
	{
		title: "Standard",
		price: "$3.99",
		description: "Standard features for regular users.",
		features: [
			{ name: "Chat Logging", included: "yes" },
			{ name: "Up to 10 Accounts", included: "yes" },
			{ name: "Send Messages & Commands", included: "yes" },
			{ name: "24/7 In the Cloud AFKing", included: "yes" },
			{ name: "VPN Bypass Proxies", included: "yes" },
			{ name: "Bot Movement", included: "yes" },
		],
	},
	{
		title: "Premium",
		price: "$5.99",
		description: "All features for power users.",
		features: [
			{ name: "Chat Logging", included: "yes" },
			{ name: "Up to 10 Accounts", included: "yes" },
			{ name: "Send Messages & Commands", included: "yes" },
			{ name: "24/7 In the Cloud AFKing", included: "yes" },
			{ name: "VPN Bypass Proxies", included: "yes" },
			{ name: "Add your own proxies", included: "yes" },
			{ name: "Bot Movement", included: "yes" },
			{ name: "Item Management", included: "yes" },
		],
	},
];

const MainPage = () => {
	return (
		<div className="relative flex flex-col items-center p-8 min-h-screen overflow-hidden bg-[#1b2029]">
			
			<header className="flex justify-between items-center w-full mb-8 px-4">
				<h1 className="text-3xl text-white font-bold flex items-center">
					<img src={logo} alt="Logo" className="h-10 mr-2" />
					Welcome to Afking.lol
				</h1>
				<div className="flex space-x-4">
					<button className="bg-[#252329] text-white rounded-lg px-4 py-2 hover:bg-[#3e434a] transition-colors">
						Store
					</button>
					<button onClick={() => window.location.href = "https://afking.lol/dash/"}
						className="bg-[#252329] text-white rounded-lg px-4 py-2 hover:bg-[#3e434a] transition-colors">
						Dashboard
					</button>
					<button className="bg-[#252329] text-white rounded-lg px-4 py-2 hover:bg-[#3e434a] transition-colors">
						Contact
					</button>
				</div>
			</header>

			{/* Featured Service Section */}
			<section className="flex justify-between items-center w-full max-w-5xl mt-10 mb-16">
				<div className="text-white">
					<h2 className="text-5xl font-bold">AFK FOR 24/7/365</h2>
					<p className="mt-4 text-lg">Providing the best, in the cloud afking experience.</p>
				</div>
				<img src={afkImage} alt="AFK Service" className="h-64 w-64 object-cover rounded-lg" />
			</section>

			{/* About Section */}
			<div className="about mt-8">
				<h2 className="text-2xl text-white mb-4">About Us</h2>
				<p className="text-white">
					We provide AFKing service.
				</p>
			</div>

			<h2 className="text-2xl text-white mb-4 mt-16">Plans to Buy</h2>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-5xl">
				{plans.map((plan, index) => (
					<Card 
						key={index} 
						title={plan.title} 
						price={plan.price} 
						description={plan.description} 
						features={plan.features} 
					/>
				))}
			</div>

		</div>
	);
};

// Card Component
const Card = ({ title, price, description, features }) => {
	return (
		<div
			className="bg-[#252329] text-white rounded-lg shadow-lg p-8 transition-transform transform hover:scale-105 hover:shadow-xl"
			style={{
				width: '300px',
				height: '550px',
			}}
		>
			<h3 className="text-xl font-bold">{title}</h3>
			<p className="text-lg">{price}</p>
			<p className="mb-4">{description}</p>

			<div className="mt-4">
				{features.map((feature, index) => (
					<div key={index} className="flex items-center mb-2">
						{feature.included === "yes" ? (
							<span className="text-green-500">✓</span>
						) : feature.included === "no" ? (
							<span className="text-red-500">✗</span>
						) : (
							<span className="text-white-500">ℹ</span> // For "question" case.
						)}
						<span className="ml-2">{feature.name}</span>
					</div>
				))}
			</div>

			<button className="bg-blue-500 text-white rounded-lg px-4 py-2 mt-4 hover:bg-blue-600 transition-colors">
				Buy Now
			</button>
		</div>
	);
};

export default MainPage;
