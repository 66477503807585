import React from 'react';
import './index.css'; // Import global CSS (for Tailwind)
import Header from './components/Header';
import MainPage from './components/MainPage';
import logo from './assets/logo.png'; // Import logo

function App() {
  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <MainPage />
      </main>
      <footer className="bg-[#191c22] text-white text-center p-4">
        <div className="flex justify-center space-x-4 mb-2">
          <a href="#">Terms of Service</a>
          <a href="#">Privacy Policy</a>
        </div>
        <span>NOT AN OFFICIAL MINECRAFT SERVICE. NOT APPROVED BY OR ASSOCIATED WITH MOJANG OR MICROSOFT.</span>
        <p>© 2024 AFKING.LOL I/S. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
